import { render, staticRenderFns } from "./disclaimer-privacy-terms.vue?vue&type=template&id=6f173386&scoped=true"
import script from "./disclaimer-privacy-terms.vue?vue&type=script&lang=js"
export * from "./disclaimer-privacy-terms.vue?vue&type=script&lang=js"
import style0 from "./disclaimer-privacy-terms.vue?vue&type=style&index=0&id=6f173386&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f173386",
  null
  
)

export default component.exports