
export default {
  props: {
    selectedVariantKey: {
      type: Object,
      default: () => ({}),
    },
    totalPrice: {
      type: [String, Number],
      default: null,
    },
    planLabelMembership: {
      type: String,
      default: null,
    },
    planLabelShort: {
      type: String,
      default: null,
    },
    tweelveWeeksPrice: {
      type: String,
      default: null,
    },
    getTermsPrivacyText: {
      type: String,
      required: true,
    },
    anchorTextTerm: {
      type: String,
      default: null,
    },
    anchorTextPrivacy: {
      type: String,
      default: null,
    },
    subscription: {
      type: String,
      default: null,
    },
  },
  methods: {
    openTermsModal() {
      this.$emit('openTermsModal');
    },
    openPrivacyModal() {
      this.$emit('openPrivacyModal');
    },
  },
};
