import { reverseApi } from '@/modules/apis/reverse-api';
import { QUIZ_VARIANTS } from '@/services/constants';

export const baseUrls = {
  terms: {
    defaultUrl: '/terms',
    specialUrl: '/terms-us',
    compliantOthers: '/terms',
  },
  privacy: {
    defaultUrl: '/privacy',
    specialUrl: '/privacy-us',
    europeAndUkUrl: '/privacy-eu',
    compliantOthers: '/privacy-eu',
  },
};

export const getBaseUrl = (type, quizVariant) => {
  const variantMap = {
    [QUIZ_VARIANTS.PILATES_NEW_CHECKOUT]: 'specialUrl',
    [QUIZ_VARIANTS.WALL_PILATES]: 'specialUrl',
    [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT]: 'defaultUrl',
    [QUIZ_VARIANTS.PILATES_28_DAY_CHAIR_CHALLENGE]: 'specialUrl',
    [QUIZ_VARIANTS.PILATES_28_DAY_FLAT_BELLY]: 'specialUrl',
    [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_SOMATIC_PILATES]: 'specialUrl',
    [QUIZ_VARIANTS.PILATES_NOW]: 'specialUrl',
    [QUIZ_VARIANTS.PILATES_LEGAL_DISCLAIMER]: 'specialUrl',
    [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_SECONDARY_TEXT]: 'specialUrl',
    [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_FULL_BODY_BEFORE_AFTER_IMAGES_V1]: 'specialUrl',
    [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_FULL_BODY_BEFORE_AFTER_IMAGES_V2]: 'specialUrl',
    [QUIZ_VARIANTS.YOGA_MENOPAUSE_CHALLENGE]: 'specialUrl',
    [QUIZ_VARIANTS.NUTRITION_MONTHLY_GENERIC_US]: 'specialUrl',
    [QUIZ_VARIANTS.NUTRITION_MONTHLY_GENERIC_OTHERS]: 'compliantOthers',
    [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GENERIC_US]: 'specialUrl',
    [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GENERIC_OTHERS]: 'compliantOthers',
    [QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_GENERIC_US]: 'specialUrl',
    [QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_GENERIC_OTHERS]: 'compliantOthers',
  };

  const variantKey = variantMap[quizVariant] || 'defaultUrl';
  return baseUrls[type][variantKey];
};

export const getFetchUrl = ({ type, locale, isEuOrUk, quizVariant }) => {
  const baseUrl =
    isEuOrUk && type === 'privacy'
      ? baseUrls[type].europeAndUkUrl || baseUrls[type].defaultUrl
      : getBaseUrl(type, quizVariant);

  return locale !== 'en' ? `${baseUrl}-${locale}` : baseUrl;
};

export const fetchContent = async (url) => {
  try {
    const response = await reverseApi.get(url, { responseType: 'text' });
    return response.data;
  } catch (error) {
    console.error(`Error fetching content ${url}:`, JSON.stringify(error));
    throw error;
  }
};

export const parseHTML = (html) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const sectionContent = doc.querySelector('.container-terms');
  return sectionContent ? sectionContent.innerHTML : 'Content not found';
};
